import { Fragment } from "react/jsx-runtime";

export function Nl2br({ text }: { text?: string | null }) {
  if (!text) {
    return "";
  }

  const lines = text.split("\n");
  return (
    <>
      {lines.map((line, i) => (
        <Fragment key={i}>
          {line}
          <br />
        </Fragment>
      ))}
    </>
  );
}
