import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Download } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useBookingsFilter } from "../useBookingsFilter";
import { useState } from "react";
import { Progress } from "@/components/ui/progress";
import { api } from "@/lib/api-client";
import flatten from "lodash/flatten";
import { ApiObjects } from "@pulso/api-client";
import * as ExcelJS from "exceljs";
import { useGridState } from "./useGridState";
import { useColumnDefinitions } from "./useColumnDefinitions";
import keyBy from "lodash/keyBy";
import { minutesToFormat } from "@pulso/utils";
import jsonpath from "jsonpath";
import { getBookingStatusLabels } from "@/components/specific/BookingStatus";
import groupBy from "lodash/groupBy";
import { getSortingFromGridStateAndColDefs } from "./sortingUtil";
import { downloadExcel } from "@/lib/downloadExcel";

type ExportExcelButtonProps = {
  facilityId: string;
};

export function ExportExcelButton(props: ExportExcelButtonProps) {
  const { t } = useTranslation();
  const filter = useBookingsFilter();
  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bookings, setBookings] = useState<ApiObjects["BookingDto"][] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { currentGridState } = useGridState(props.facilityId);
  const colDefs = useColumnDefinitions(props.facilityId);
  const bookingStatusLabels: Record<string, string> = getBookingStatusLabels(t);

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          reset();
          setIsOpen(isOpen);
        }}
      >
        <DialogTrigger asChild>
          <Button variant="outline">
            <Download size={16} />
          </Button>
        </DialogTrigger>
        <DialogContent hideFooter>
          <DialogHeader>
            <DialogTitle>{t("bookings_grid_export_title", "Export bookings")}</DialogTitle>
            <DialogDescription>
              {t(
                "bookings_grid_export_description",
                "The bookings you see on the screen are going to be exported in an Excel file."
              )}
            </DialogDescription>
          </DialogHeader>
          {!isExporting && !bookings && (
            <div>
              <Button onClick={onExport}>{t("bookings_grid_export_exportButton", "Export")}</Button>
            </div>
          )}
          {isExporting && <Progress value={progress} />}
          {bookings && (
            <div className="flex flex-col gap-4">
              <div className="text-sm">{t("bookings_grid_export_ready", "Your export is ready.")}</div>
              <div>
                <Button onClick={downloadExportedFile}>{t("common_button_download", "Download")}</Button>
              </div>
              {error && <div className="text-destructive">{error}</div>}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
  function reset() {
    setIsExporting(false);
    setProgress(0);
    setBookings(null);
  }

  async function onExport() {
    reset();
    setIsExporting(true);

    const bookings = await collectAllBookings();
    setIsExporting(false);
    setBookings(bookings);
  }

  async function collectAllBookings() {
    const pages = [];
    const pageSize = 25;
    let page = 1;
    let totalPages = 1;
    while (page <= totalPages) {
      const bookingsPage = await api.getBookings({
        facilityId: props.facilityId,
        ...filter.forApi(page),
        ...getSortingFromGridStateAndColDefs(currentGridState, colDefs),
        pageSize,
        range: currentGridState.rangeType ?? "fullBooking",
      });
      totalPages = Math.ceil(bookingsPage.total / pageSize);
      pages.push(bookingsPage.bookings);
      page++;
      setProgress(((page - 1) / totalPages) * 100);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    return flatten(pages);
  }

  async function downloadExportedFile() {
    if (!bookings) {
      return;
    }

    try {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Pulso";
      workbook.lastModifiedBy = "Pulso";
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet("Export");
      const visibleColumns = currentGridState.columns.filter((c) => !c.hide).map((c) => c.colId);
      const colDefsMap = keyBy(colDefs, "field");

      sheet.columns = visibleColumns
        .filter((columnId) => colDefsMap[columnId].context?.skipExport !== true)
        .map((columnId) => {
          const colDef = colDefsMap[columnId];
          const colFormat = colDef.context?.exportFormat;

          return {
            header: colDef.headerName,
            key: columnId,
            ...(colFormat === "date" || colFormat === "price"
              ? {
                  style: {
                    numFmt: colFormat === "date" ? "dd mmm yyyy hh:mm" : "€0.00",
                  },
                  width: 18,
                }
              : {}),
          };
        });

      sheet.autoFilter = {
        from: "A1",
        to: sheet.getCell(1, sheet.columns.length).address,
      };

      bookings.forEach((booking) => {
        const row = sheet.columns.map((column) => {
          const colDef = colDefsMap[column.key!];

          const colFormat = colDef.context?.exportFormat;
          const field: string = colDef.field!;
          const extractedValue = jsonpath.query(booking, field)[0];
          const value =
            typeof colDef.valueGetter === "function"
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                colDef.valueGetter({ data: booking, getValue: () => extractedValue } as any)
              : extractedValue;
          if (typeof colDef.valueFormatter === "function" && colFormat !== "price" && colFormat !== "date") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return colDef.valueFormatter({ value, data: booking } as any);
          }
          return formatValue(booking, value, colFormat);
        });
        sheet.addRow(row);
      });

      await downloadExcel("export.xlsx", workbook);
    } catch (e: unknown) {
      if (typeof e === "object" && e && "message" in e && typeof e.message === "string") {
        setError(e.message);
      }
    }
  }

  function formatValue(booking: ApiObjects["BookingDto"], value: string, format: string): string | Date | number {
    if (format && value) {
      switch (format) {
        case "date":
          return new Date(value.replace("T", " "));
        case "price":
          return parseFloat(value);
        case "bookingStatus":
          return bookingStatusLabels[value];
        case "duration":
          return minutesToFormat(value);
        case "items":
          return getItems()
            .map((item) => `${item.count} x ${item.name}`)
            .join(", ");
      }
    }

    return value;

    function getItems() {
      return Object.values(groupBy(booking.items || [], (i) => i.productVariantId)).map((items) => ({
        name: items[0].product.name + (items[0].variantName ? ` ${items[0].variantName}` : ""),
        count: items.length,
      }));
    }
  }
}
