import { PropsWithChildren, ReactElement, useState } from "react";
import { Edit2, CheckCircle, X } from "lucide-react";
import { cn } from "./utils";
import { Spinner } from "./Spinner";

type EditableProps<TValue> = PropsWithChildren<{
  value: TValue;
  onChange: (value: NoInfer<TValue>) => unknown;
  element: (props: { value: NoInfer<TValue>; onChange: (value: NoInfer<TValue>) => void }) => ReactElement;
  className?: string;
  disableEdit?: boolean;
}>;

export function Editable<TValue>(props: EditableProps<TValue>) {
  const [value, setValue] = useState(props.value);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={cn("relative", props.className)}>
      {isEditing ? (
        <div className="flex items-center gap-1 ">
          {props.element({ value, onChange: setValue })}
          <div className="absolute left-full pl-1 flex items-center gap-1">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsLoading(true);
                    const result = props.onChange(value);
                    if (typeof result === "object" && result && result instanceof Promise) {
                      result.finally(() => {
                        setIsEditing(false);
                        setIsLoading(false);
                      });
                    } else {
                      setIsEditing(false);
                      setIsLoading(false);
                    }
                  }}
                >
                  <CheckCircle size={16} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsEditing(false);
                    setValue(props.value);
                  }}
                >
                  <X size={16} />
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={"flex items-center gap-1 cursor-pointer"}
          onClick={() => !props.disableEdit && setIsEditing(true)}
        >
          {props.children}
          {!props.disableEdit && (
            <div className="absolute left-full pl-1 flex items-center gap-1">
              <Edit2 size={14} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
