import { ApiObjects } from "@pulso/api-client";
import { useColumnDefinitions } from "./useColumnDefinitions";
import { GridState } from "./useGridState";

export function getSortingFromGridStateAndColDefs(
  currentGridState: GridState,
  colDefs: ReturnType<typeof useColumnDefinitions>
) {
  const sortingCol = currentGridState.columns.find((s) => s.sort);
  if (!sortingCol) {
    return {};
  }

  return getSortingFromSortingModelAndColDefs(sortingCol, colDefs);
}

export function getSortingFromSortingModelAndColDefs(
  sortingCol: { sort?: "asc" | "desc" | null; colId: string },
  colDefs: ReturnType<typeof useColumnDefinitions>
) {
  if (!sortingCol || !sortingCol.colId) {
    return {};
  }

  const sortColDef = colDefs.find((col) => sortingCol.colId === col.field);

  if (!sortColDef) {
    return {};
  }

  return {
    sort: (sortColDef.context?.sortParam ?? sortColDef.field) as ApiObjects["BookingsQuery"]["sort"],
    sortOrder: sortingCol.sort,
    ...(sortColDef.context?.sortCustomerFieldId ? { sortFieldId: sortColDef.context?.sortCustomerFieldId } : {}),
  };
}
