import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useProductUpdate(productId: string | undefined) {
  return useMutation({
    mutationFn: async (data: ApiObjects["UpdateProductBody"]) =>
      productId ? await api.updateProduct({ productId }, data, { formData: true }) : null,
    invalidateKeys: [["products"], ["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}
