import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingItemUpdatePrice(bookingItemId: string) {
  return useMutation({
    mutationFn: async (price: number) => {
      await api.updateBookingItemPrice({ bookingItemId }, { price });
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}
