import { useBookings } from "@/api/useBookings";
import { DateFormat, formatDate } from "@/components/specific/DateFormat";
import { Price } from "@pulso/components/lib/Price";
import { Spinner } from "@/components/ui/spinner";
import { t } from "i18next";
import { MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { cn, groupBy, groupByArray } from "@/lib/utils";
import { Warning } from "@/components/specific/Warning";
import { useBookingsFilter } from "./useBookingsFilter";
import { useBookingsDashboardSettings } from "./useBookingsDashboardSettings";
import { useVirtualizer, Range, defaultRangeExtractor } from "@tanstack/react-virtual";
import { ApiObjects } from "@pulso/api-client";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { BookingDate } from "@/components/specific/BookingDate";
import "./BookingsMultiDayList.css";
import { useWindowResizeVersion } from "@/lib/useWindowResizeVersion";
import { useAuth } from "@/lib/useAuth";
import { useLocations } from "@/api/useLocations";
import keyBy from "lodash/keyBy";
import { BookingStatusButton } from "@/components/specific/BookingStatusButton";
import { BookingContextMenu } from "./context-menu/BookingContextMenu";
import { useTranslation } from "react-i18next";
import { useBookingConfirm } from "@/api/useBookingConfirm";
import { useBookingReturn } from "@/api/useBookingReturn";
import { useBookingDeliver } from "@/api/useBookingDeliver";
import { zonedStartOfDay } from "@pulso/utils";
import { addDays, isAfter, isBefore } from "date-fns";
import { ButtonLoadable } from "@/components/ui/button-loadable";
import { BgColorBadge } from "@/components/ui/bg-color-badge";

type BookingItem = ApiObjects["BookingDto"] & {
  products: { name: string; color: string; count: number; isDoubleBooked: boolean; insufficientStock: number }[];
};
type BookingRow = {
  booking: BookingItem;
};
type GroupRow = { key: string };

const GROUP_ROW_HEIGHT_PHONE = 36;
const GROUP_ROW_HEIGHT_LARGE = 64;
const BOOKING_ROW_HEIGHT_PHONE = 160; // 201;
const BOOKING_ROW_HEIGHT_LARGE = 67;

export const BOOKING_STATUS_MARKER_CLASS: Record<ApiObjects["BookingDto"]["status"], string> = {
  UNCONFIRMED: "bg-yellow-400",
  PENDING: "bg-green-500",
  IN_PROGRESS: "bg-blue-400",
  COMPLETED: "bg-gray-300",
  CANCELLED: "bg-zinc-700",
  ON_HOLD: "bg-orange-300",
};

export function BookingsMultiDayList({ facilityId }: { facilityId: string }) {
  const filter = useBookingsFilter();
  const bookingsRaw = useBookings({ ...filter.forApi(1), to: "" });
  const { facility } = useAuth();
  const navigate = useNavigate();
  const { settings } = useBookingsDashboardSettings();
  const parentRef = useRef<HTMLDivElement>(null);
  const activeStickyIndexRef = useRef(0);
  const { locations } = useLocations(facilityId);
  const locationsMap = keyBy(locations, (l) => l.id);
  const [bookingRowHeight, setBookingRowHeight] = useState(calculateBookingRowHeight());

  const bookings: BookingItem[] = bookingsRaw.items.map((booking) => ({
    ...booking,
    products: Object.values(groupBy(booking.items, (i) => i.productVariantId)).map((items) => ({
      name: items[0].product.name + (items[0].variantName ? ` ${items[0].variantName}` : ""),
      color: items[0].product.color,
      count: items.length,
      isDoubleBooked: items.some((i) => i.isDoubleBooked),
      insufficientStock: items.find((i) => i.availability < 0)?.availability ?? 0,
    })),
  }));

  const bookingGroups = groupByArray(
    bookings,
    (booking) => (booking.status === "IN_PROGRESS" ? booking.endAt : booking.startAt).substring(0, 10) + "T00:00:00"
  );

  const rows: Array<GroupRow | BookingRow> = [];

  const stickyIndexes: number[] = [];

  for (const group of bookingGroups) {
    stickyIndexes.push(rows.length);
    rows.push({ key: group.key });
    group.items.forEach((booking) => {
      rows.push({ booking });
    });
  }

  const isSticky = (index: number) => stickyIndexes.includes(index);
  const isActiveSticky = (index: number) => activeStickyIndexRef.current === index;

  const rowVirtualizer = useVirtualizer({
    count: bookingsRaw.hasMore ? rows.length + 1 : rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (i) => (isGroup(rows[i]) ? bookingRowHeight.group : bookingRowHeight.booking),
    gap: 4,
    overscan: 5,
    rangeExtractor: useCallback(
      (range: Range) => {
        let i = range.startIndex;
        while (i > 0 && rows[i] && !isGroup(rows[i])) i--;
        activeStickyIndexRef.current = Math.max(0, i);

        const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);

        return Array.from(next).sort((a, b) => a - b);
      },
      [stickyIndexes, bookingRowHeight]
    ),
  });

  const rowVirtualizerItems = rowVirtualizer.getVirtualItems();
  const lastItem = rowVirtualizerItems[rowVirtualizerItems.length - 1];

  useEffect(() => {
    if (!lastItem) {
      return;
    }

    if (lastItem.index >= rows.length - 1 && bookingsRaw.hasMore && !bookingsRaw.isLoading) {
      bookingsRaw.next();
    }
  }, [bookingsRaw.isLoading, rows.length, lastItem?.index]);

  const resizeVersion = useWindowResizeVersion();

  useEffect(() => {
    const newHeight = calculateBookingRowHeight();
    if (newHeight.booking !== bookingRowHeight.booking) {
      rowVirtualizerItems.map((item) =>
        rowVirtualizer.resizeItem(item.index, isGroup(rows[item.index]) ? newHeight.group : newHeight.booking)
      );
      setBookingRowHeight(newHeight);
    }
  }, [resizeVersion, locations]);

  if (bookingsRaw.isLoading) {
    return <Spinner />;
  }

  if (bookingsRaw.total === 0) {
    return (
      <div className="text-muted-foreground text-center p-12">
        {t("bookings_list_empty", "No bookings found after {{date}}", { date: formatDate(filter.date, "date") })}
      </div>
    );
  }

  return (
    <div ref={parentRef} className="h-full overflow-y-auto lg:-mr-6">
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => (
          <div
            key={virtualItem.key}
            style={{
              ...(isSticky(virtualItem.index)
                ? {
                    zIndex: 1,
                  }
                : {}),
              ...(isActiveSticky(virtualItem.index)
                ? {
                    position: "sticky",
                  }
                : {
                    position: "absolute",
                    transform: `translateY(${virtualItem.start}px)`,
                  }),
              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualItem.size}px`,
            }}
          >
            <Row row={rows[virtualItem.index]} />
          </div>
        ))}
      </div>
    </div>
  );

  function Row({ row }: { row: GroupRow | BookingRow }) {
    if (!row) {
      if (bookingsRaw.hasMore) {
        return <CentralSpinner />;
      } else {
        return <EndMessage />;
      }
    }

    if (isGroup(row)) {
      return (
        <div className="py-1 md:pt-6 md:pb-3 text-lg bg-[#f8f7f4]">
          <button type="button" onClick={() => filter.setDate(row.key)}>
            {facility?.timezone && (
              <DateFormat date={row.key} format="date-short-words" showToday={true} timezone={facility.timezone} />
            )}
          </button>
        </div>
      );
    }

    return <BookingItemRow booking={row.booking} />;
  }

  function BookingItemRow({ booking }: { booking: BookingItem }) {
    const nameAndItemsDivRef = useRef<HTMLDivElement>(null);
    const [invisibleItemsLeft, setInvisibleItemsLeft] = useState(0);

    const resizeVersion = useWindowResizeVersion();

    useEffect(() => {
      const el = nameAndItemsDivRef.current;
      if (el) {
        const elBbox = el.getBoundingClientRect();
        const hasInvisible = elBbox.height > 50;

        if (hasInvisible) {
          const children = el.children[1].children;
          let i = 0;
          for (; i < children.length; i++) {
            const child = children[i];
            const bbox = child.getBoundingClientRect();
            if (bbox.top - elBbox.top > 30 && i > 0) {
              const lastVisibleElementBbox = children[i - 1].getBoundingClientRect();
              setInvisibleItemsLeft(lastVisibleElementBbox.left + lastVisibleElementBbox.width - elBbox.left);
              break;
            }
          }
        } else {
          setInvisibleItemsLeft(0);
        }
      }
    }, [nameAndItemsDivRef.current, resizeVersion]);

    return (
      <div
        onClick={() => navigate(`./${booking.id}`)}
        className={cn(
          "bookings-list__row py-2 pl-3 pr-0 lg:pr-0 lg:mr-6 bg-white rounded-md mb-1 border-b cursor-pointer flex-col hover:bg-gray-50",
          "lg:pl-6"
        )}
        style={{ height: bookingRowHeight.booking + "px" }}
      >
        <div
          className="w-24 hidden lg:block"
          style={{ gridArea: "bookingStatus" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <BookingStatusButton booking={booking} />
        </div>

        <div
          className={cn(
            "w-1 lg:hidden absolute top-0 left-0 bottom-0 rounded-l-md",
            BOOKING_STATUS_MARKER_CLASS[booking.status]
          )}
          style={{ gridArea: "bookingStatusIndicator" }}
        ></div>

        {/* Schedule */}
        <div style={{ gridArea: "bookingSchedule" }}>
          <BookingDate booking={booking} periodLabel={settings.periodLabel} />
          {locations.length > 0 && (
            <div className="text-xs flex justify-start px-1 pt-1 gap-1 text-muted-foreground">
              <div className="flex-1 flex items-center gap-0.5">
                {locationsMap[booking.startLocationId || ""]?.name || "??"}
              </div>
              <div>
                <MapPin size={14} />
              </div>
              <div className="flex-1 flex justify-end items-center gap-0.5">
                {locationsMap[booking.endLocationId || ""]?.name || "??"}
              </div>
            </div>
          )}
        </div>

        <div className="flex-1 max-h-12 overflow-hidden relative px-3 md:px-0" style={{ gridArea: "customerName" }}>
          <div
            ref={nameAndItemsDivRef}
            className="flex flex-col md:pl-3 lg:pl-3 xl:pl-9 xl:flex-row xl:space-x-3 xl:items-start"
          >
            <div className="min-w-40 md:min-w-64 flex items-center justify-center md:justify-start xl:h-12">
              <div className="md:max-md:max-w-52 text-ellipsis whitespace-nowrap overflow-hidden text-md font-medium">
                {booking.customer.name}
              </div>
              <span className="text-sm pl-1">(#{booking.reference})</span>
            </div>
            {booking.products.length > 0 ? (
              <div className="min-w-64 xl:min-h-12 py-1 flex items-center flex-wrap gap-1.5 justify-center md:justify-start">
                {booking.products.map((product) => (
                  <BgColorBadge
                    key={product.name}
                    className="text-xs font-normal py-0"
                    color={product.color}
                    variant="outline"
                  >
                    {product.count} x {product.name}{" "}
                    {(product.insufficientStock || product.isDoubleBooked) && (
                      <Warning
                        size={12}
                        className="ml-1"
                        tooltip={
                          product.isDoubleBooked
                            ? t("bookings_items_doubleBooking_tooltip", "This item is double booked")
                            : t("booking_items_availability_insufficientItems_label", "{{num}} short", {
                                num: -product.insufficientStock,
                              })
                        }
                      />
                    )}
                  </BgColorBadge>
                ))}
                {!!invisibleItemsLeft && (
                  <div className="absolute pl-1 bottom-1 ml-3 md:ml-0" style={{ left: invisibleItemsLeft + "px" }}>
                    ...
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {!!booking.items.length && (
          <div style={{ gridArea: "bookingPayments" }} className="min-w-32 pl-3">
            {booking.invoice.totalDue ? (
              <span
                className={cn(
                  (booking.status === "IN_PROGRESS" || booking.status === "COMPLETED") && "text-destructive"
                )}
              >
                <span className="text-xs">{t("bookings_table_header_paymentLeft", "To be paid")}</span>
                <br />
                <Price price={booking.invoice.totalDue} currency={booking.currency} />
              </span>
            ) : (
              <span className="text-sm text-green-700 flex gap-1 flex-col">
                {t("bookings_table_header_paymentDone", "Fully paid")}
                <Price price={booking.invoice.totalPayment} currency={booking.currency} />
              </span>
            )}
          </div>
        )}
        <div
          className="ml-auto pr-3 md:pr-0"
          style={{ gridArea: "bookingActions" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center gap-3">
            <QuickActionButton booking={booking} />
            <BookingContextMenu booking={booking} />
          </div>
        </div>
      </div>
    );
  }

  function EndMessage() {
    return (
      <div className="p-6 space-y-6 text-muted-foreground text-center">
        <div className="text-sm">
          {t("bookings_table_endOfList_message", "That's all for the period {{from}} - {{to}}.", {
            from: formatDate(filter.date, "date", true),
            to: formatDate(filter.dateTo, "date", true),
          })}
        </div>
      </div>
    );
  }

  function calculateBookingRowHeight() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const extraForLocations = locations.length > 0 ? 20 : 0;
    return vw > 768
      ? { booking: BOOKING_ROW_HEIGHT_LARGE + extraForLocations, group: GROUP_ROW_HEIGHT_LARGE }
      : { booking: BOOKING_ROW_HEIGHT_PHONE + extraForLocations, group: GROUP_ROW_HEIGHT_PHONE };
  }
}

function isGroup(d: BookingRow | GroupRow): d is GroupRow {
  return d && "key" in d;
}

function QuickActionButton({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const { t } = useTranslation();
  const deliverBooking = useBookingDeliver(booking.id, booking.items.length);
  const returnBooking = useBookingReturn(booking.id);
  const confirmBooking = useBookingConfirm(booking.id);

  if (booking.items.length === 0) {
    return null;
  }

  return (
    <>
      {booking.status === "PENDING" && isBookingWithinDeliveryRange(booking.startAt, booking.timezone) ? (
        <ButtonLoadable
          variant="outline"
          size="sm"
          onClick={() => deliverBooking.mutate()}
          isLoading={deliverBooking.isPending}
        >
          {t("bookings_details_deliver_button")}
        </ButtonLoadable>
      ) : booking.status === "IN_PROGRESS" ? (
        <ButtonLoadable
          variant="outline"
          size="sm"
          onClick={() => returnBooking.mutate()}
          isLoading={returnBooking.isPending}
        >
          {t("bookings_details_return_button")}
        </ButtonLoadable>
      ) : booking.status === "UNCONFIRMED" ? (
        <ButtonLoadable
          variant="outline"
          size="sm"
          onClick={() => confirmBooking.mutate()}
          isLoading={confirmBooking.isPending}
        >
          {t("bookings_details_confirm_button")}
        </ButtonLoadable>
      ) : null}
    </>
  );

  function isBookingWithinDeliveryRange(startAt: string, timezone: string): boolean {
    const nowInTz = zonedStartOfDay(timezone);
    return isAfter(startAt, nowInTz) && isBefore(startAt, addDays(nowInTz, 2));
  }
}
