import { BookingStatusButton } from "@/components/specific/BookingStatusButton";
import { Warning } from "@/components/specific/Warning";
import { BgColorBadge } from "@/components/ui/bg-color-badge";
import { ApiObjects } from "@pulso/api-client";
import { LanguageFlag } from "@pulso/components/lib/LanguageFlag";
import { formatDate, formatPrice, getCreatedViaTranslation, minutesToFormat, translateLanguage } from "@pulso/utils";
import { ColDef } from "ag-grid-community";
import groupBy from "lodash/groupBy";
import { useTranslation } from "react-i18next";
import { GridData } from "./GridData";
import { useCustomerFields } from "@/api/useCustomerFields";
import { WhatsAppLink } from "@/components/specific/WhatsAppLink";
import { WhatsAppIcon } from "@pulso/components/lib/WhatsAppIcon";
import { cn } from "@pulso/components/lib/utils";
import { InvoiceName } from "../details/InvoiceName";
import { TooltipSimple } from "@/components/ui/tooltip";
import { ImagePreview } from "@pulso/components/lib/ImagePreview";

export const dateTimeFormatter = (params: { value: string }) => (params.value ? formatDate(params.value) : "");
export const dateFormatter = (params: { value: string }) =>
  params.value ? formatDate(params.value, null, undefined, "date") : "";
export const priceFormatter = (params: { value: number; data?: { currency: string } }) =>
  typeof params.value === "number" && params.data ? formatPrice(params.value, params.data.currency) : "";

export function useColumnDefinitions(facilityId: string) {
  const { t } = useTranslation();
  const { fields, isLoading } = useCustomerFields(facilityId);

  if (!fields || isLoading) {
    return [];
  }

  const columns = [
    {
      field: "reference",
      headerName: t("dashboard_columnName_bookingReference", "Reference"),
      sortable: true,
      hide: false,
      width: 100,
    },
    {
      field: "status",
      headerName: t("dashboard_columnName_bookingStatus", "Status"),
      cellRenderer: (params: { data?: ApiObjects["BookingDto"] }) =>
        params.data && (
          <div className="h-full flex items-center">
            <BookingStatusButton booking={params.data} />
          </div>
        ),
      context: { noClick: true, exportFormat: "bookingStatus" },
      sortable: true,
      hide: false,
      width: 140,
    },
    {
      field: "pickUp",
      headerName: t("dashboard_columnName_bookingPickUp", "Pick-up"),
      valueGetter: (params: { data?: ApiObjects["BookingDto"] }) =>
        params.data?.deliveredAt ?? params.data?.startAt ?? "",
      cellRenderer: (params: { value: string; data?: ApiObjects["BookingDto"] }) =>
        params.data ? (
          <TooltipSimple
            text={
              params.data.deliveredAt
                ? t("dashboard_columnName_bookingPickUp_tooltip", "Scheduled pick-up: {{date}}", {
                    date: dateTimeFormatter({ value: params.data.startAt }),
                  })
                : ""
            }
          >
            {dateTimeFormatter(params)}
          </TooltipSimple>
        ) : null,
      sortable: true,
      hide: false,
      width: 170,
      context: { sortParam: "startAt", exportFormat: "date" },
      initialSort: "asc",
    },
    {
      field: "return",
      headerName: t("dashboard_columnName_bookingReturn", "Return"),
      valueGetter: (params: { data?: ApiObjects["BookingDto"] }) => params.data?.returnedAt ?? params.data?.endAt ?? "",
      cellRenderer: (params: { value: string; data?: ApiObjects["BookingDto"] }) =>
        params.data ? (
          <TooltipSimple
            text={
              params.data.returnedAt
                ? t("dashboard_columnName_bookingReturn_tooltip", "Scheduled return: {{date}}", {
                    date: dateTimeFormatter({ value: params.data.endAt }),
                  })
                : ""
            }
          >
            {dateTimeFormatter(params)}
          </TooltipSimple>
        ) : null,
      sortable: true,
      hide: false,
      width: 170,
      context: { sortParam: "endAt", exportFormat: "date" },
    },
    {
      field: "startAt",
      headerName: t("dashboard_columnName_deliveredAt", "Scheduled pick-up"),
      valueFormatter: dateTimeFormatter,
      hide: true,
      width: 170,
      sortable: false,
      context: { exportFormat: "date" },
    },
    {
      field: "endAt",
      headerName: t("dashboard_columnName_returnedAt", "Scheduled return"),
      valueFormatter: dateTimeFormatter,
      hide: true,
      width: 170,
      sortable: false,
      context: { exportFormat: "date" },
    },
    {
      field: "period.name",
      headerName: t("dashboard_columnName_periodName", "Period"),
      sortable: true,
      context: { sortParam: "period.name" },
      hide: false,
      width: 150,
    },
    {
      field: "period.period",
      headerName: t("dashboard_columnName_periodDuration", "Duration"),
      valueFormatter: (params: { value: number }) => minutesToFormat(params.value),
      sortable: true,
      context: { sortParam: "period", exportFormat: "duration" },
      hide: true,
      width: 100,
    },
    {
      field: "customer.name",
      headerName: t("dashboard_columnName_customerName", "Customer"),
      sortable: true,
      hide: false,
      width: 250,
    },
    {
      field: "customer.firstname",
      headerName: t("dashboard_columnName_customerFirstName", "First name"),
      hide: true,
      sortable: false,
      width: 150,
    },
    {
      field: "customer.lastname",
      headerName: t("dashboard_columnName_customerLastName", "Last name"),
      hide: true,
      sortable: false,
      width: 150,
    },
    {
      field: "customer.email",
      headerName: t("dashboard_columnName_customerEmail", "E-mail"),
      sortable: false,
      hide: true,
      width: 220,
    },
    {
      field: "customer.language",
      headerName: t("dashboard_columnName_customerLanguage", "Language"),
      valueFormatter: (params: { value: string }) => translateLanguage(t, params.value),
      cellRenderer: (params: { value: string; valueFormatted: string }) => {
        return params.value ? (
          <div className="h-full flex items-center gap-1">
            <LanguageFlag lang={params.value} /> {params.valueFormatted}
          </div>
        ) : (
          params.value
        );
      },
      sortable: false,
      hide: true,
      width: 150,
    },
    {
      field: "items",
      headerName: "Items",
      width: 400,
      sortable: false,
      hide: false,
      cellRenderer: (params: { data?: ApiObjects["BookingDto"] }) => <ItemsCell data={params.data} />,
      context: { exportFormat: "items" },
    },
    { field: "notes", headerName: t("dashboard_columnName_notes", "Notes"), sortable: false, hide: false },
    {
      field: "createdVia",
      headerName: t("dashboard_columnName_createdVia", "Source"),
      valueFormatter: (params: { value: string }) =>
        params.value ? getCreatedViaTranslation(t, params.value as "APP") : params.value,
      sortable: true,
      hide: true,
      width: 200,
      context: { sortParam: "createdVia" },
    },
    {
      field: "createdAt",
      headerName: t("dashboard_columnName_createdAt", "Created"),
      valueFormatter: dateTimeFormatter,
      sortable: true,
      hide: true,
      width: 170,
      context: { exportFormat: "date" },
    },
    {
      field: "invoice.deposit.amount",
      headerName: t("dashboard_columnName_deposit", "Deposit"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalPeanlty",
      headerName: t("dashboard_columnName_totalPenlties", "Penalties"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalDiscount",
      headerName: t("dashboard_columnName_totalDiscount", "Discount"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalPayment",
      headerName: t("dashboard_columnName_totalPayments", "Payments"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalPrice",
      headerName: t("dashboard_columnName_totalPrice", "Price"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalBeforeTax",
      headerName: t("dashboard_columnName_totalBeforeTax", "Before tax"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalAfterTax",
      headerName: t("dashboard_columnName_totalAfterTax", "After tax"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalTax",
      headerName: t("dashboard_columnName_totalTax", "Tax"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.totalDue",
      headerName: t("dashboard_columnName_totalDue", "Total due"),
      valueFormatter: priceFormatter,
      sortable: false,
      hide: true,
      width: 120,
      context: { exportFormat: "price" },
    },
    {
      field: "invoice.paymentStatus",
      headerName: t("dashboard_columnName_paymentStatus", "Payment status"),
      hide: false,
      cellRenderer: (params: { data?: ApiObjects["BookingDto"] }) => <PaymentStatusCell data={params.data} />,
      sortable: false,
      width: 160,
      context: { skipExport: true },
    },
    {
      field: "invoice.paymentType",
      headerName: t("dashboard_columnName_paymentType", "Payment type"),
      valueFormatter: (params: { data?: ApiObjects["BookingDto"] }) => {
        return params.data?.invoice.payments.map((p) => InvoiceName(t, p.method)).join(", ");
      },
      sortable: false,
      hide: true,
      width: 180,
    },
    {
      field: "productIdentifiers",
      headerName: t("dashboard_columnName_productIdentifiers", "Product ID's"),
      valueFormatter: (params: { data?: ApiObjects["BookingDto"] }) => {
        return params.data?.items
          .filter((i) => i.stockItem)
          .map((i) => i.stockItem?.identifier)
          .join(", ");
      },
      sortable: false,
      hide: true,
      width: 200,
    },
    {
      field: "payments",
      headerName: t("dashboard_columnName_payments", "Payments"),
      valueFormatter: (params: { data?: ApiObjects["BookingDto"] }) => {
        return !params.data
          ? ""
          : params.data.invoice.payments
              .map((p) => `${formatPrice(p.amount, params.data?.currency || "EUR")} (${InvoiceName(t, p.method)})`)
              .join(", ");
      },
      sortable: false,
      hide: true,
      width: 200,
    },
    ...fields.map((field) => ({
      field: `customer.fields.${field.id}`,
      valueGetter: (params: { data?: ApiObjects["BookingDto"] | undefined }) => {
        const fieldValue = params.data?.customer.fields?.find((f: { id: string }) => f.id === field.id);
        if (field.type === "FILE") {
          return fieldValue;
        } else {
          return fieldValue?.value;
        }
      },
      ...(field.type === "DATE" ? { valueFormatter: dateFormatter } : {}),
      headerName: field.name,
      sortable: true,
      hide: true,
      context: {
        sortParam: "customer.field",
        sortCustomerFieldId: field.id,
        noClick: field.type === "FILE",
        skipExport: field.type === "FILE",
        exportFormat: field.type === "DATE" ? "date" : undefined,
      },
      // filter: CustomFilter,
      cellRenderer: (params: {
        value: string | { value: string; file: ApiObjects["FileDto"] };
        valueFormatted: string;
      }) => {
        if (!params.value) {
          return null;
        }
        if (typeof params.value === "object" && "file" in params.value && params.value.file.url) {
          return <ImagePreview url={params.value.file.url} name={params.value.value} />;
        }

        return (
          <div className="h-full flex items-center gap-1">
            {params.valueFormatted ?? params.value}
            {field.type === "PHONE" && params.value && typeof params.value === "string" && (
              <WhatsAppLink phone={params.value}>
                <WhatsAppIcon />
              </WhatsAppLink>
            )}
          </div>
        );
      },
    })),
  ] as ColDef<GridData>[];

  return columns;
}

function ItemsCell(params: { data?: ApiObjects["BookingDto"] }) {
  const { t } = useTranslation();
  const nonPackagedItems = (params.data?.items || []).filter((i) => !i.parentId);
  const productsGroups = Object.values(groupBy(nonPackagedItems, (i) => i.productVariantId)).map((items) => ({
    name: items[0].product.name + (items[0].variantName ? ` ${items[0].variantName}` : ""),
    color: items[0].product.color,
    count: items.length,
    isDoubleBooked: items.some((i) => i.isDoubleBooked),
    insufficientStock: items.find((i) => i.availability < 0)?.availability ?? 0,
  }));

  return (
    <div className="flex flex-wrap items-center gap-x-1 gap-y-0 h-full">
      {productsGroups.map((product) => (
        <BgColorBadge key={product.name} className="text-xs font-normal py-0" variant="outline" color={product.color}>
          {product.count} x {product.name}{" "}
          {(product.insufficientStock || product.isDoubleBooked) && (
            <Warning
              size={12}
              className="ml-1"
              tooltip={
                product.isDoubleBooked
                  ? t("bookings_items_doubleBooking_tooltip", "This item is double booked")
                  : t("booking_items_availability_insufficientItems_label", "{{num}} short", {
                      num: -product.insufficientStock,
                    })
              }
            />
          )}
        </BgColorBadge>
      ))}
    </div>
  );
}

function PaymentStatusCell(params: { data?: ApiObjects["BookingDto"] }) {
  const { t } = useTranslation();

  if (!params.data) {
    return null;
  }

  return (
    <div
      className={cn(
        "flex flex-col gap-1 justify-center h-full",
        params.data.invoice.totalDue > 0
          ? (params.data?.status === "IN_PROGRESS" || params.data?.status === "COMPLETED") && "text-destructive"
          : "text-green-700 "
      )}
      style={{ lineHeight: "1em" }}
    >
      {params.data.invoice.totalDue > 0 ? (
        <>
          <div>{t("bookings_table_header_paymentLeft", "To be paid")}</div>
          <div>{params.data && formatPrice(params.data.invoice.totalDue, params.data.currency)}</div>
        </>
      ) : (
        <>
          <div>{t("bookings_table_header_paymentDone", "Fully paid")}</div>
          <div>{params.data && formatPrice(params.data.invoice.totalPayment, params.data.currency)}</div>
        </>
      )}
    </div>
  );
}
