import { ChevronDown, Edit, LayoutDashboardIcon, SlidersHorizontal, Trash2, X } from "lucide-react";
import { t } from "i18next";

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { useBookingsDashboardSettings } from "./useBookingsDashboardSettings";
import { SelectNative } from "@pulso/components/lib/SelectNative";
import { Separator } from "@/components/ui/separator";
import { BookGridColumnsPopover } from "./grid/BookGridColumnsPopover";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { gridRowHeightAtom, useGridState } from "./grid/useGridState";
import { useDashboardViewDelete } from "@/api/useDashboardViewDelete";
import { cn } from "@/lib/utils";
import { InfoBox } from "@/components/specific/InfoBox";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { useAtom } from "jotai";

export function BookingsDashboardSettings(props: { facilityId: string }) {
  const { settings, setPeriodLabel, setViewMode } = useBookingsDashboardSettings();
  const [newDashboardName, setNewDashboardName] = useState("");
  const {
    saveAsNewDashboardView,
    dashboardViews,
    activateDashboardView,
    saveDashboardViewUpdates,
    activeDashboardView,
    updateDashboardName,
    currentGridState,
    updateCurrentGridState,
    hasChanges,
  } = useGridState(props.facilityId);
  const deleteDashboardView = useDashboardViewDelete();
  const [saveNewOpen, setSaveNewOpen] = useState(false);
  const defaultManagerDashbaordName = t("bookings_dashboard_settings_dashboard_manager_default", "Manager view");
  const [editingDashboardId, setEditingDashboardId] = useState<string | null>(null);
  const [editedDashboardName, setEditedDashboardName] = useState<string>("");
  const [gridRowHeight, setGridRowHeight] = useAtom(gridRowHeightAtom);

  return (
    <div className="flex items-center gap-3">
      {hasChanges &&
        (activeDashboardView ? (
          <Button variant="link" size="sm" onClick={() => saveDashboardViewUpdates()} className="px-0">
            {t("bookings_dashboard_settings_dashboardViews_update", "Save changes to {{dashboardName}}", {
              dashboardName: activeDashboardView.name,
            })}
          </Button>
        ) : (
          <Button
            variant="link"
            size="sm"
            onClick={() =>
              saveAsNewDashboardView(
                t("bookings_dashboard_settings_dashboardViews_new_defaultFirstName", "Dashboard #1")
              )
            }
            className="px-0"
          >
            {t("bookings_dashboard_settings_dashboardViews_saveFirst", "Save changes")}
          </Button>
        ))}
      <Popover
        onOpenChange={() => {
          setSaveNewOpen(false);
          setEditingDashboardId(null);
        }}
      >
        <PopoverTrigger asChild>
          <Button variant="outline" className="gap-2">
            <LayoutDashboardIcon size={16} />
            {settings.viewMode === "RECEPTION"
              ? t("bookings_dashboard_settings_viewMode_option_reception", "Reception")
              : (activeDashboardView?.name ?? defaultManagerDashbaordName)}{" "}
            <ChevronDown size={16} />
          </Button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-72 p-0">
          <div className="flex flex-col text-sm">
            {[
              {
                id: "reception",
                name: t("bookings_dashboard_settings_viewMode_option_reception", "Reception"),
                noEdit: true,
              },
              { id: "manager", name: defaultManagerDashbaordName, noEdit: true },
              ...dashboardViews,
            ].map((view) => (
              <div
                key={view.id}
                onClick={() => onViewChange(view.id)}
                onDoubleClick={() => {
                  if (view.id !== "manager" && view.id !== "reception") {
                    setEditingDashboardId(view.id);
                    setEditedDashboardName(view.name);
                  }
                }}
                className={cn(
                  "flex items-center gap-2 border-b py-1 px-3 hover:bg-secondary cursor-pointer group h-12",
                  isDashboardViewActive(view.id) && "bg-secondary font-bold"
                )}
              >
                <LayoutDashboardIcon size={16} />
                {editingDashboardId !== view.id ? (
                  <>{view.name}</>
                ) : (
                  <div className="flex flex-1 items-center gap-2" onClick={(e) => e.stopPropagation()}>
                    <Input
                      className="flex-1 px-1 -mx-1 font-normal"
                      value={editedDashboardName}
                      onChange={(e) => setEditedDashboardName(e.target.value)}
                    />
                    <Button
                      size="sm"
                      onClick={() =>
                        updateDashboardName(editingDashboardId, editedDashboardName, () => {
                          setEditingDashboardId(null);
                        })
                      }
                    >
                      {t("common_button_save", "Save")}
                    </Button>
                    <Button size="sm" variant="ghost" className="px-0" onClick={() => setEditingDashboardId(null)}>
                      <X size={16} />
                    </Button>
                  </div>
                )}
                {!editingDashboardId && view.id !== "reception" && view.id !== "manager" && (
                  <div className={cn("ml-auto")}>
                    <Button
                      variant="ghost"
                      className="px-1.5"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingDashboardId(view.id);
                        setEditedDashboardName(view.name);
                      }}
                    >
                      <Edit size={16} />
                    </Button>
                    <ConfirmDialog
                      title={t("bookings_dashboard_settings_dashboardViews_deleteDialog_title", "Delete dashboard")}
                      description={t(
                        "bookings_dashboard_settings_dashboardViews_deleteDialog_description",
                        "Are you sure you want to delete the dashboard {{name}}?",
                        { name: view.name }
                      )}
                      onContinue={() => deleteDashboardView.mutate(view.id)}
                    >
                      <Button variant="ghost" className="px-1.5" onClick={(e) => e.stopPropagation()}>
                        <Trash2 size={16} />
                      </Button>
                    </ConfirmDialog>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="px-3 py-2">
            {saveNewOpen ? (
              <div className="flex gap-2">
                <Input
                  className="text-sm"
                  placeholder={t("bookings_dashboard_settings_dashboardViews_new_name", "New dashboard name")}
                  value={newDashboardName}
                  onChange={(e) => setNewDashboardName(e.target.value)}
                />
                <Button onClick={() => onSaveAsNewDashboard()} variant="outline">
                  {t("bookings_dashboard_settings_dashboardViews_saveNew", "Save")}
                </Button>
              </div>
            ) : (
              <div className="flex items-center justify-between text-primary">
                <Button variant="link" size="sm" className="px-0" onClick={() => setSaveNewOpen(true)}>
                  {t("bookings_dashboard_settings_dashboardViews_new_title", "Save as new dashboard")}
                </Button>
                <InfoBox
                  text={t(
                    "bookings_dashboard_settings_dashboardViews_new_tooltip",
                    "Save the current column order as a new dashboard"
                  )}
                />
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline">
            <SlidersHorizontal size={16} />
          </Button>
        </SheetTrigger>
        <SheetContent className="flex flex-col">
          <SheetHeader>
            <SheetTitle>{t("bookings_dashboard_settings_title", "Dashboard settings")}</SheetTitle>
            <SheetDescription>
              {t("bookings_dashboard_settings_description", "Personalize your dashboard view")}
            </SheetDescription>
          </SheetHeader>
          <Separator className="my-0" />
          <div className="p-6 overflow-y-auto flex flex-col gap-6">
            {settings.viewMode === "RECEPTION" && (
              <div className="flex flex-col gap-1 text-sm">
                <div>{t("bookings_dashboard_settings_period", "Period label")}</div>
                <div>
                  <SelectNative
                    value={settings.periodLabel}
                    onChange={(v) => setPeriodLabel(v.target.value === "DURATION" ? "DURATION" : "LABEL")}
                  >
                    <option value="DURATION">
                      {t("bookings_dashboard_settings_period_option_duration", "Duration")}
                    </option>
                    <option value="LABEL">{t("bookings_dashboard_settings_period_option_label", "Label")}</option>
                  </SelectNative>
                </div>
              </div>
            )}
            {settings.viewMode === "MANAGER" && (
              <>
                <div className="flex flex-col gap-1 text-sm">
                  <div>{t("bookings_dashboard_settings_gridRowHeight", "Row height")}</div>
                  <SelectNative value={gridRowHeight} onChange={(e) => setGridRowHeight(parseInt(e.target.value))}>
                    <option value="40">{t("bookings_dashboard_settings_gridRowHeight_narrow", "Narrow")}</option>
                    <option value="50">{t("bookings_dashboard_settings_gridRowHeight_medium", "Medium")}</option>
                    <option value="70">{t("bookings_dashboard_settings_gridRowHeight_comfy", "Comfy")}</option>
                  </SelectNative>
                </div>
                <div className="flex flex-col gap-1 text-sm">
                  <div>{t("bookings_dashboard_settings_rangeType_label", "Inclusion criteria")}</div>
                  <SelectNative
                    value={currentGridState.rangeType}
                    onChange={(e) =>
                      updateCurrentGridState(
                        {
                          ...currentGridState,
                          rangeType: e.target.value as "startDate" | "fullBooking",
                        },
                        true
                      )
                    }
                  >
                    <option value="fullBooking">
                      {t("bookings_dashboard_settings_rangeType_fullBooking", "Partial overlap with date range")}
                    </option>
                    <option value="startDate">
                      {t("bookings_dashboard_settings_rangeType_startDate", "Delivery date within date range")}
                    </option>
                  </SelectNative>
                </div>
                <div className="flex flex-col gap-1 text-sm">
                  <div>{t("bookings_dashboard_settings_columns", "Visible columns")}</div>
                  <BookGridColumnsPopover facilityId={props.facilityId} />
                </div>
              </>
            )}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );

  function isDashboardViewActive(viewId: string) {
    if (settings.viewMode === "MANAGER") {
      return viewId === "manager" ? !activeDashboardView : viewId === activeDashboardView?.id;
    }

    return viewId === "reception";
  }

  function onViewChange(viewId: string) {
    if (viewId === "reception") {
      setViewMode("RECEPTION");
    } else if (viewId === "manager") {
      setViewMode("MANAGER");
      activateDashboardView(null);
    } else {
      setViewMode("MANAGER");
      activateDashboardView(viewId);
    }
  }

  function onSaveAsNewDashboard() {
    saveAsNewDashboardView(newDashboardName);
    setNewDashboardName("");
    setSaveNewOpen(false);
  }
}
