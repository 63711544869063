import { UseTranslationResponse } from "react-i18next";

export function InvoiceName(t: UseTranslationResponse<"", unknown>[0], type: string) {
  switch (type) {
    case "DISCOUNT":
      return t("bookings_invoice_discounts_form_reason_1", "Discount");
    case "CASH":
      return t("bookings_invoice_payments_form_option_cash", "Cash");
    case "CARD":
      return t("bookings_invoice_payments_form_option_card", "Card");
    case "TRANSFER":
      return t("bookings_invoice_payments_form_option_transfer", "Bank Transfer");
    case "PAYPAL":
      return "PayPal";
    case "DELAY":
      return t("bookings_invoice_penalties_form_option_delay", "Delayed return");
    case "DAMAGE":
      return t("bookings_invoice_penalties_form_option_damage", "Damaged item");
    case "LOST":
      return t("bookings_invoice_penalties_form_option_lost", "Lost item");
    case "OTHER":
      return t("bookings_invoice_penalties_form_option_other", "Other");
    default:
      return type;
  }
}
