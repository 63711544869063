import { Badge, BadgeProps } from "./badge";

type BgBadgeColorProps = BadgeProps & {
  color: string;
};

export function BgColorBadge({ color, ...props }: BgBadgeColorProps) {
  let textColor = "";

  const bgColorParts = color.match(/#(..)(..)(..)/);
  if (bgColorParts) {
    const r = parseInt(bgColorParts[1], 16) / 255;
    const g = parseInt(bgColorParts[2], 16) / 255;
    const b = parseInt(bgColorParts[3], 16) / 255;
    const luminance = (c: number) => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4));
    const relativeLuminance = 0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);
    textColor = relativeLuminance > 0.5 ? "" : "white";
  }

  return (
    <Badge
      {...props}
      variant="outline"
      style={{ ...(props.style || {}), ...(textColor ? { color: textColor } : {}), backgroundColor: color }}
    />
  );
}
