import { Button } from "@/components/ui/button";
import { ApiObjects } from "@pulso/api-client";
import { Minus, Plus } from "lucide-react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useRentalPeriods } from "@/api/useRentalPeriods";
import { AssignStockItemModal } from "./AssignStockItemModal";
import { useBookingItemDelete } from "@/api/useBookingItemDelete";
import { useBookingItemCreate } from "@/api/useBookingItemCreate";
import { useBookingItemUpdate } from "@/api/useBookingItemUpdate";
import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipSimple, TooltipTrigger } from "@/components/ui/tooltip";
import { Warning } from "@/components/specific/Warning";
import { t } from "i18next";
import { Price } from "@pulso/components/lib/Price";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { usePeriodsWithPrice } from "@/api/usePeriodsWithPrice";
import groupBy from "lodash/groupBy";
import { formatPrice, notEmpty } from "@pulso/utils";
import { useBookingItemUpdatePrice } from "@/api/useBookingItemUpdatePrice";
import { Editable } from "@pulso/components/lib/Editable";
import { Nl2br } from "@pulso/components/lib/Nl2br";
import { PriceInput } from "@/components/ui/price-input";
import { useHasRight } from "@/lib/useHasRight";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

type BookingItemProps = {
  booking: ApiObjects["BookingDto"];
  variant: {
    items: ApiObjects["BookingItemDto"][];
    period: ApiObjects["BookingItemDto"]["period"];
    fields: ApiObjects["BookingItemDto"]["fields"];
    price: number;
    singlePrice: number | null;
    hasMissingPrice: boolean;
    key: string;
    availability: number;
    product: ApiObjects["ProductDto"];
    firstUnassignedItem: ApiObjects["BookingItemDto"] | null;
    children: { itemId: string; items: ApiObjects["BookingItemDto"][] }[];
  };
};

export function BookingItem({ booking, variant }: BookingItemProps) {
  const { periods } = useRentalPeriods();
  const deleteMutation = useBookingItemDelete();
  const duplicateBookingItem = useBookingItemCreate(booking.id);
  const updateBookingItem = useBookingItemUpdate(
    booking.id,
    variant.items[0].productVariantId,
    variant.items[0].period.id
  );
  const updatePrice = useBookingItemUpdatePrice(variant.items[0].id);
  const hasRightToChangePrice = useHasRight("changeBookingItemPrice");
  const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = useState(false);
  const hasCustomPrice = variant.items.some((i) => i.customPrice);
  const priceGroups = Object.entries(groupBy(variant.items.map((i) => i.price)));
  const priceLines = priceGroups.map(
    ([price, items]) => `${items.length} x ${formatPrice(parseFloat(price), booking.currency)}`
  );
  const hasMultiplePrices = priceGroups.length > 1;
  const customPricesLabel = hasCustomPrice ? priceLines.join("\n") : "";

  return (
    <>
      <div className="grid gap-3 w-full py-2 bookingItem__gridCols">
        <div className="flex flex-wrap items-center -m-0.5 mr-1">
          <Badge wrap variant={variant.children.length > 0 ? "dark" : "secondary"} className="font-bold m-0.5">
            {variant.product.name}
          </Badge>
          {variant.fields.map((f) => (
            <Badge wrap variant="secondary" key={f.fieldId} className="m-0.5">
              {f.value}
            </Badge>
          ))}
        </div>
        <div className="flex items-center">
          {booking.status !== "COMPLETED" && (
            <>
              {!hasMultiplePrices ? (
                <Button
                  className="mr-3"
                  variant="outline"
                  size="sm"
                  disabled={deleteMutation.isPending}
                  onClick={(e) => {
                    variant.firstUnassignedItem
                      ? deleteMutation.mutate(variant.firstUnassignedItem.id)
                      : deleteMutation.mutate(variant.items[0].id);
                    e.stopPropagation();
                  }}
                >
                  <Minus size={16} />
                </Button>
              ) : (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button className="mr-3" variant="outline" size="sm" disabled={deleteMutation.isPending}>
                      <Minus size={16} />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="p-0 w-48" align="start">
                    {priceGroups.map(([price]) => (
                      <div
                        key={price}
                        className="p-2 border-b last:border-none cursor-pointer text-sm hover:bg-secondary"
                        onClick={() => {
                          const item = variant.items.find((i) => i.price + "" === price);
                          if (item) {
                            deleteMutation.mutate(item.id);
                          }
                        }}
                      >
                        {t("bookings_items_multiplePrices_removePopover_label", "Remove {{price}}", {
                          price: formatPrice(parseFloat(price), booking.currency),
                        })}
                      </div>
                    ))}
                  </PopoverContent>
                </Popover>
              )}
            </>
          )}
          <div>{variant.items.length}</div>
          {booking.status !== "COMPLETED" && (
            <Button
              variant="outline"
              className="ml-3"
              size="sm"
              disabled={duplicateBookingItem.isPending}
              onClick={(e) => {
                e.stopPropagation();
                duplicateBookingItem.mutate({
                  periodId: variant.period.id,
                  fields: variant.fields.map((field) => ({
                    fieldId: field.fieldId,
                    value: field.type === "TEXT" ? field.value : field.optionId,
                  })),
                  productId: variant.product.id,
                });
              }}
            >
              <Plus size={16} />
            </Button>
          )}
        </div>
        <div className="flex items-center">
          <Link to={`../inventory/${variant.product.id}`} className="flex items-center">
            <Availability availability={variant.availability} status={booking.status} />
          </Link>
        </div>
        <div
          className={cn(
            "flex items-center",
            (periods.length || 0) > 1 && booking.status !== "COMPLETED" && "justify-end"
          )}
        >
          {(periods.length || 0) > 1 && booking.status !== "COMPLETED" ? (
            <Select
              defaultValue={variant.period.id}
              onValueChange={(newPeriodId) => {
                updateBookingItem.mutate(newPeriodId);
              }}
              open={isPeriodDropdownOpen}
              onOpenChange={setIsPeriodDropdownOpen}
            >
              <SelectTrigger className="max-w-full bg-white">
                <SelectValue>{variant.period.name}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                {isPeriodDropdownOpen && (
                  <PeriodDropdownOptions productId={variant.product.id} fields={variant.fields} />
                )}
              </SelectContent>
            </Select>
          ) : (
            variant.period.name
          )}
        </div>
        <div className="flex justify-end items-center">
          {variant.hasMissingPrice || variant.singlePrice === null ? (
            <Tooltip>
              <TooltipTrigger>
                <Link to={`../products/${variant.product.id}`}>
                  <Warning />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                {t("bookings_items_warning_priceMissing", "The price for this product and period is missing")}
              </TooltipContent>
            </Tooltip>
          ) : (
            <>
              <div className="pr-3 md:hidden">{t("bookings_items_header_singlePrice", "Price")}:</div>
              <Editable<number | undefined>
                value={variant.items[0]?.price ?? 0}
                element={(props) => <PriceInput {...props} className="w-32 bg-white" />}
                onChange={(price) => price && updatePrice.mutateAsync(price)}
                disableEdit={!hasRightToChangePrice || booking.status === "COMPLETED"}
              >
                {hasMultiplePrices ? (
                  <div className="text-sm">
                    <Nl2br text={customPricesLabel} />
                  </div>
                ) : (
                  <Price price={variant.singlePrice} currency={booking.currency} />
                )}
              </Editable>
            </>
          )}
        </div>
        <div className="flex justify-end items-center font-bold">
          <div className="pr-3 md:hidden">{t("bookings_items_header_totalPrice", "Total")}:</div>
          <Price price={variant.price} currency={booking.currency} />
        </div>
      </div>
      <Separator />
      {variant.children.length > 0 && (
        <div>
          {variant.children
            .sort((a, b) => (a.itemId < b.itemId ? -1 : 1))
            .map(({ itemId, items }, packIndex) => (
              <div key={itemId}>
                {variant.children.length > 1 && (
                  <div className="text-xs pt-3">
                    {t("bookings_items_pack_number", "Pack #{{num}}", { num: packIndex + 1 })}
                  </div>
                )}
                {Object.entries(groupBy(items, (i) => i.product.id))
                  .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                  .map(([productId, groupedItems]) => (
                    <div key={productId} className="my-3 ml-3 flex items-center gap-3">
                      <div>
                        <Badge variant="secondary" className="flex">
                          {groupedItems.length} x {groupedItems[0].product.name}
                        </Badge>
                      </div>
                      {booking.status !== "COMPLETED" && groupedItems[0].availability < 0 && (
                        <TooltipSimple
                          text={t(
                            "booking_items_availability_insufficientItems_tooltip",
                            "Insufficient stock items. {{num}} extra item(s) are required to fulfill your bookings.",
                            { num: -groupedItems[0].availability }
                          )}
                        >
                          <span className="text-destructive flex items-center text-xs">
                            <Warning className="mr-1" size={14} />
                            {t("booking_items_availability_insufficientItems_label", "{{num}} short", {
                              num: -groupedItems[0].availability,
                            })}
                          </span>
                        </TooltipSimple>
                      )}
                      {items.find((i) => i.product.id === productId)?.product.identifiable && (
                        <div className="flex-1">
                          <AssignStockItemModal
                            facilityId={booking.facilityId}
                            productId={productId}
                            items={groupedItems}
                          ></AssignStockItemModal>
                        </div>
                      )}
                    </div>
                  ))}
                <Separator />
              </div>
            ))}
        </div>
      )}
      {variant.product.identifiable && (
        <>
          <div className="flex items-center space-x-3 py-3">
            <AssignStockItemModal
              facilityId={booking.facilityId}
              productId={variant.product.id}
              items={variant.items}
            ></AssignStockItemModal>
          </div>
          <Separator />
        </>
      )}
    </>
  );
}

function Availability({ availability, status }: { availability: number; status: ApiObjects["BookingDto"]["status"] }) {
  if (status === "COMPLETED" || typeof availability !== "number") {
    return null;
  }

  if (availability < 0) {
    return (
      <TooltipSimple
        text={t(
          "booking_items_availability_insufficientItems_tooltip",
          "Insufficient stock items. {{num}} extra item(s) are required to fulfill your bookings.",
          { num: -availability }
        )}
      >
        <span className="text-destructive flex items-center">
          <Warning className="mr-2" />
          {t("booking_items_availability_insufficientItems_label", "{{num}} short", { num: -availability })}
        </span>
      </TooltipSimple>
    );
  }
  return <>{t("booking_items_label_available", "{{num}} left", { num: availability })}</>;
}

function PeriodDropdownOptions({
  productId,
  fields,
}: {
  productId: string;
  fields: ApiObjects["BookingItemDto"]["fields"];
}) {
  const fieldValues = fields
    .map((f) => f.optionId)
    .filter(notEmpty)
    .map((value) => ({ value }));
  const periods = usePeriodsWithPrice(productId, fieldValues);

  return (
    <SelectGroup>
      {periods
        .filter((p) => p.enabled && typeof p.price === "number")
        .map((period) => (
          <SelectItem key={period.id} value={period.id}>
            {period.name}
          </SelectItem>
        ))}
    </SelectGroup>
  );
}
