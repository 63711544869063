import { useBookingDocumentsSignUrl } from "@/api/useBookingDocumentsSignUrl";
import { WhatsAppLink } from "@/components/specific/WhatsAppLink";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { TooltipSimple } from "@/components/ui/tooltip";
import { ApiObjects } from "@pulso/api-client";
import { WhatsAppIcon } from "@pulso/components/lib/WhatsAppIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

type BookingSignModalProps = {
  bookingId: string;
  documents: ApiObjects["BookingDocumentDto"][];
  phone?: string | null;
  language: string;
};

export function BookingSignModal({ bookingId, phone, language }: BookingSignModalProps) {
  const { t, i18n } = useTranslation();
  const { url, isLoading } = useBookingDocumentsSignUrl(bookingId);

  const [whatsAppMessage, setWhatsAppMessage] = useState("");

  useEffect(() => {
    i18n.loadLanguages(language).then(() => {
      if (url) {
        setWhatsAppMessage(
          t(
            "bookings_details_documents_sign_modal_whatsApp_message",
            "Please, sign the contract by clicking on the following link. The link will remain active for 72 hours. \n {{link}}",
            {
              link: url,
              lng: language,
            }
          )
        );
      }
    });
  }, [url, language, i18n, t]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex items-center gap-3">
      {phone && (
        <TooltipSimple
          text={t("bookings_details_documents_sign_modal_whatsApp_tooltip", "Send the sign link via WhatsApp")}
        >
          <WhatsAppLink phone={phone} message={whatsAppMessage}>
            <WhatsAppIcon size={24} />
          </WhatsAppLink>
        </TooltipSimple>
      )}
      <Dialog>
        <DialogTrigger asChild>
          <Button size="sm" variant="secondary">
            {t("booking_details_documents_sign_button", "Sign All")}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("bookings_details_documents_sign_modal_title", "Sign documents")}</DialogTitle>
            <DialogDescription>
              {t(
                "bookings_details_documents_sign_modal_description",
                "Ask your customer to scan this QR code, so that they can sign all documents on their phone."
              )}
            </DialogDescription>
          </DialogHeader>
          {url && (
            <div className="flex flex-col items-center space-y-3">
              <QRCode value={url} />
              <a href={url} target="_blank">
                <Button variant="secondary">
                  {t("bookings_details_documents_sign_modal_signOnTheSameDevice_button", "Open in a new window")}
                </Button>
              </a>
              {phone && (
                <WhatsAppLink phone={phone} message={whatsAppMessage}>
                  <Button variant="outline" className="flex gap-2 items-center">
                    <WhatsAppIcon /> {t("whatsApp_common_button", "Share via WhatsApp")}
                  </Button>
                </WhatsAppLink>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
