import { useDashboardViewCreate } from "@/api/useDashboardViewCreate";
import { useDashboardViews } from "@/api/useDashboardViews";
import { useDashboardViewUpdate } from "@/api/useDashboardViewUpdate";
import { ApiObjects } from "@pulso/api-client";
import { atom, useAtom, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type ColState = { colId: string; width?: number; hide?: boolean | null; sort?: "asc" | "desc" | null }[];
export type GridState = { columns: ColState; rangeType: ApiObjects["BookingsQuery"]["range"] };

export const activeDashboardViewIdAtom = atomWithStorage<string | null>(
  "booking-grid-active-dashboard-view-id",
  null,
  undefined,
  { getOnInit: true }
);
export const currentGridStateAtom = atomWithStorage<GridState>(
  "booking-grid-current-grid-state",
  getDefaultGridState(),
  undefined,
  { getOnInit: true }
);
export const gridRowHeightAtom = atomWithStorage<number>("booking-grid-row-height", 50, undefined, {
  getOnInit: true,
});
export const currentGridStateVersionAtom = atom(0);
export const hasChangesAtom = atom(false);

export function useGridState(facilityId: string) {
  const [activeDashboardViewId, setActiveDashboardViewId] = useAtom(activeDashboardViewIdAtom);
  const [currentGridState, setCurrentGridState] = useAtom(currentGridStateAtom);
  const setCurrentGridStateVersion = useSetAtom(currentGridStateVersionAtom);
  const { dashboardViews } = useDashboardViews(facilityId);
  const updateDashboardViewMutation = useDashboardViewUpdate();
  const createDashboardViewMutation = useDashboardViewCreate(facilityId);
  const activeDashboardView = dashboardViews.find((view) => view.id === activeDashboardViewId);
  const [hasChanges, setHasChanges] = useAtom(hasChangesAtom);

  function saveDashboardViewUpdates() {
    if (activeDashboardView) {
      updateDashboardViewMutation.mutate(
        {
          id: activeDashboardView.id,
          name: activeDashboardView.name,
          state: currentGridState,
        },
        { onSuccess: () => setHasChanges(false) }
      );
    }
  }

  function saveAsNewDashboardView(name: string) {
    createDashboardViewMutation.mutate(
      { name, state: currentGridState },
      {
        onSuccess(res) {
          setActiveDashboardViewId(res.id);
          setHasChanges(false);
        },
      }
    );
  }

  function activateDashboardView(viewId: string | null) {
    setActiveDashboardViewId(viewId);
    const newState = dashboardViews.find((view) => view.id === viewId)?.state;

    if (newState) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCurrentGridState(newState as any);
      setCurrentGridStateVersion((v) => v + 1);
      setHasChanges(false);
    } else {
      setCurrentGridState(getDefaultGridState());
      setCurrentGridStateVersion((v) => v + 1);
      setHasChanges(false);
    }
  }

  function updateCurrentGridState(newState: Partial<GridState>, updateVersion = false) {
    setCurrentGridState({ ...currentGridState, ...newState });
    if (updateVersion) {
      setCurrentGridStateVersion((v) => v + 1);
    }
    setHasChanges(true);
  }

  function updateDashboardName(id: string, name: string, onSuccess?: () => void) {
    const view = dashboardViews.find((v) => v.id === id);
    if (view) {
      updateDashboardViewMutation.mutate({ id, name, state: view.state }, { onSuccess });
    }
  }

  function toggleVisibility(colId: string, isVisible: boolean) {
    const colToUpdate = currentGridState.columns.find((c) => c.colId === colId);

    if (colToUpdate) {
      updateCurrentGridState(
        {
          ...currentGridState,
          columns: currentGridState.columns.map((c) => (c.colId === colId ? { ...c, hide: !isVisible } : c)),
        },
        true
      );
    } else {
      updateCurrentGridState(
        {
          ...currentGridState,
          columns: [...currentGridState.columns, { colId, width: 200, sort: null, hide: !isVisible }],
        },
        true
      );
    }
  }

  return {
    dashboardViews,
    activeDashboardView,
    currentGridState,
    updateCurrentGridState,
    activateDashboardView,
    saveDashboardViewUpdates,
    saveAsNewDashboardView,
    hasChanges,
    updateDashboardName,
    toggleVisibility,
  };
}

function getDefaultGridState() {
  return {
    rangeType: "fullBooking" as const,
    columns: [
      { colId: "reference", width: 100, hide: false, sort: null },
      { colId: "status", width: 140, hide: false, sort: null },
      { colId: "pickUp", width: 170, hide: false, sort: "asc" as const },
      { colId: "return", width: 170, hide: false, sort: null },
      { colId: "startAt", width: 170, hide: true, sort: null },
      { colId: "endAt", width: 170, hide: true, sort: null },
      { colId: "period.name", width: 150, hide: false, sort: null },
      { colId: "period.period", width: 100, hide: true, sort: null },
      { colId: "customer.name", width: 250, hide: false, sort: null },
      { colId: "customer.firstname", width: 150, hide: true, sort: null },
      { colId: "customer.lastname", width: 150, hide: true, sort: null },
      { colId: "customer.email", width: 220, hide: true, sort: null },
      { colId: "customer.language", width: 150, hide: true, sort: null },
      { colId: "items", width: 400, hide: false, sort: null },
      { colId: "notes", width: 200, hide: false, sort: null },
      { colId: "createdVia", width: 200, hide: true, sort: null },
      { colId: "createdAt", width: 170, hide: true, sort: null },
      { colId: "invoice.deposit.amount", width: 120, hide: true, sort: null },
      { colId: "invoice.totalPeanlty", width: 120, hide: true, sort: null },
      { colId: "invoice.totalDiscount", width: 120, hide: true, sort: null },
      { colId: "invoice.totalPayment", width: 120, hide: true, sort: null },
      { colId: "invoice.totalPrice", width: 120, hide: true, sort: null },
      { colId: "invoice.totalBeforeTax", width: 120, hide: true, sort: null },
      { colId: "invoice.totalAfterTax", width: 120, hide: true, sort: null },
      { colId: "invoice.totalTax", width: 120, hide: true, sort: null },
      { colId: "invoice.totalDue", width: 120, hide: true, sort: null },
      { colId: "invoice.paymentStatus", width: 160, hide: false, sort: null },
      { colId: "invoice.paymentType", width: 180, hide: true, sort: null },
    ],
  };
}
