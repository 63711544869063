import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { t } from "i18next";
import { PropsWithChildren, useState } from "react";
import { z } from "zod";
import { useBookingAddDeposit } from "@/api/useBookingAddDeposit";

const formSchema = z.object({
  amount: z.number(),
  method: z.enum(["CASH", "CARD", "TRANSFER", "PAYPAL"]),
  concept: z.string().optional(),
});

type AddDepositModalProps = PropsWithChildren<{
  bookingId: string;
  currency: string;
}>;

export function AddDepositModal({ bookingId, currency, children }: AddDepositModalProps) {
  const [open, setOpen] = useState(false);
  const form = createForm(
    {
      amount: {
        label: t("bookings_invoice_deposit_form_label_amount", "Amount"),
        type: "price",
        sign: currency,
      },
      method: {
        label: t("bookings_invoice_deposit_form_label_method", "Payment method"),
        type: "select",
        placeholder: "",
        options: [
          { label: t("bookings_invoice_deposit_form_option_cash", "Cash"), value: "CASH" },
          { label: t("bookings_invoice_deposit_form_option_card", "Card"), value: "CARD" },
          { label: t("bookings_invoice_deposit_form_option_transfer", "Bank Transfer"), value: "TRANSFER" },
          { label: "PayPal", value: "PAYPAL" },
        ],
      },
      concept: {
        label: t("bookings_invoice_deposit_form_label_notes", "Notes"),
        type: "text",
      },
    },
    formSchema
  );

  const add = useBookingAddDeposit(bookingId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("bookings_deposit_form_title", "Add deposit")}</DialogTitle>
          <DialogDescription>
            {t("bookings_deposit_form_subtitle", "Note down the deposit left by the customer")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={add.isPending}
          onSubmit={(values) => add.mutate(values, { onSuccess: () => setOpen(false) })}
          initialValues={{
            amount: 0,
            method: "CARD",
          }}
        ></PulsoFormProvider>
      </DialogContent>
    </Dialog>
  );
}
