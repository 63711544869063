import { z } from "zod";
import { createForm } from "../../components/specific/Form";
import { useTranslation } from "react-i18next";
import { zodFile } from "@pulso/components/utils/zodFile";

export function useUpdateProductForm(options?: { hideIdentifiable?: boolean }) {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(2).max(50),
    description: z.string().optional(),
    image: zodFile(t, { optional: true, allowedTypes: ["jpeg", "jpg", "png", "gif"] }),
    identifiable: z.boolean(),
    vatEnabled: z.boolean(),
    vat: z.number().optional(),
    color: z.string(),
  });

  return createForm(
    {
      name: {
        label: t("products_create_form_label_name", "Name"),
        type: "string",
      },
      description: {
        label: t("products_create_form_label_description", "Description"),
        type: "text",
      },
      image: {
        label: t("products_create_form_label_photo", "Photo"),
        type: "image",
      },
      identifiable: {
        label: t("products_create_form_label_identifiable", "Identification"),
        type: "radio",
        options: [
          {
            value: "true",
            typedValue: true,
            label: t(
              "products_create_form_option_unitary",
              "Track each stock item individually with a uniquely assigned code"
            ),
          },
          {
            value: "false",
            typedValue: false,
            label: t(
              "products_create_form_option_complement",
              "Similar products that don't need to be uniquely tracked (complements, insurance, etc.)"
            ),
          },
        ],
        hideIf: () => options?.hideIdentifiable === true,
      },
      vatEnabled: {
        label: t("products_create_form_label_vatEnabled", "Enable VAT"),
        placeholder: t("products_create_form_placeholder_vatEnabled", "Enable VAT for this product"),
        type: "switch",
      },
      vat: {
        label: t("products_create_form_label_vatEnabled", "VAT"),
        type: "number",
        prefix: "%",
        hideIf: (v) => !v.vatEnabled,
        className: "w-32",
      },
      color: {
        label: t("products_create_form_label_color", "Color"),
        info: t("products_create_form_info_color", "You can use colors to quickly identify products in the dashboard."),
        type: "color",
      },
    },
    formSchema
  );
}
